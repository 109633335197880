import React, { Fragment } from 'react';
import CALVI_example from './CALVI_example.png';
import MI_Symptom_App_Screenshot from './MI_Symptom_App_Screenshot.PNG';
import UROP_Screenshot from './UROP_Screenshot.PNG';
import MDP_Project_Intro_Slide_Screenshot from './MDP_Project_Intro_Slide_Screenshot.PNG';
import adaptive_flow from './adaptive_flow.png';
import vframer_logo from './vframer_logo.png';

function Research() {
	return (
		<Fragment>
			<div class="research" id="researchSection">
				<h2>Project Highlights</h2>
				<div class="experience">
					<div class="projectDetails">
						<div>
							<img class="block" src={vframer_logo} alt="V-FRAMER snapshot"/>
						</div>
						<div class="details">
							<p>"How can we guard against more subtle errors in reasoning when designing visualizations?"</p>
							<p>A visualization framework that captures knowledge in avoiding pitfalls in visualization creation.</p>
						</div>
					</div>
					<div class="no-top-margin publication">
						<p class="no-top-margin"><b>V-FRAMER: Visualization Framework for Mitigating Reasoning Errors in Public Policy</b></p>
						<p><b>Lily W. Ge</b>, Matthew Easterday, Matthew Kay, Evanthia Dimara, Peter Cheng, and Steven L Franconeri</p>
						<p>ACM Human Factors in Computing Systems (CHI) 2024</p>
						<p><a target="_blank" rel="noopener noreferrer" href="https://osf.io/y3pd6/">[ PDF ]</a> <a target="_blank" rel="noopener noreferrer" href="https://osf.io/q3uta/">[ OSF ]</a></p>
					</div>
					<div class="projectDetails">
						<div>
							<img class="block" src={adaptive_flow} alt="adaptive flow"/>
						</div>
						<div class="details">
							<p>Assessments developed to adaptively measure people's visualization literacy.</p>
							<p>A-VLAT and A-CALVI each contains half the number of questions than their original versions and still measures the same set of skills.</p>
						</div>
					</div>
					<div class="no-top-margin publication">
						<p class="no-top-margin"><b>Adaptive Assessment of Visualization Literacy</b></p>
						<p>Yuan Cui, <b>Lily W. Ge</b>, Yiren Ding, Fumeng Yang, Lane Harrison, and Matthew Kay</p>
						<p>IEEE Trans. Visualization & Comp. Graphics (Proc. INFOVIS) 2023</p>
						<p><a target="_blank" rel="noopener noreferrer" href="https://arxiv.org/pdf/2308.14147.pdf">[ PDF ]</a> <a target="_blank" rel="noopener noreferrer" href="https://osf.io/a6258/">[ OSF ]</a></p>
					</div>
					<div class="projectDetails">
						<div>
							<img class="block" src={CALVI_example} alt="CALVI example"/>
						</div>
						<div class="details">
							<p>"How well can the general public identify misinformation in visualizations?"</p>
							<p>An assessment developed to measure people's susceptibility to visualization misinformation.</p>
							<p>With a focus on the critical thinking, <i>CALVI</i> offers new perspectives on the notion of visualization literacy.</p>
						</div>
					</div>
					<div class="no-top-margin publication">
						<p class="no-top-margin"><b>CALVI: Critical Thinking Assessment for Literacy in Visualizations</b></p>
						<p><b>Lily W. Ge</b>, Yuan Cui, Matthew Kay</p>
						<p>ACM Human Factors in Computing Systems (CHI) 2023 | <span class="award">Honorable Mention Award (top 5%)</span></p>
						<p><a target="_blank" rel="noopener noreferrer" href="https://osf.io/32mg7">[ PDF ]</a> <a target="_blank" rel="noopener noreferrer" href="https://osf.io/pv67z/">[ OSF ]</a></p>
					</div>
					<div class="projectDetails">
						<div>
							<img class="block" src={MI_Symptom_App_Screenshot} alt="MI Symptom App Screenshot"/>
						</div>
						<div class="details">
							<p><b>MI Symptoms App</b></p>
							<p>A statewide online tool to help individuals and employers in the state of Michigan to check for symptoms related to COVID-19 and help businesses reopen safely.</p>
							<p>An effort to help Michigan public health officials to discover and prevent any possible COVID-19 outbreaks in the state of Michigan.</p>
							<li>Focus on User Interface development and improving user experience</li>
							<p><a target="_blank" rel="noopener noreferrer" href="https://cse.engin.umich.edu/stories/students-lead-the-way-on-state-of-michigan-web-application-to-help-curb-the-spread-of-covid-19">Learn more</a></p>
						</div>
					</div>
					<div class="projectDetails">
						<div>
							<img class="block" src={UROP_Screenshot} alt="UROP Screenshot"/>
						</div>
						<div class="details">
							<p><b>Alexa Let's Chat</b></p>
							<p>Worked with the University of Michigan Audrey team and competed in the Amazon’s Alexa Prize competition: Alexa Prize Socialbot Grand Challenge 3, and the team entered semi-finals.</p>
							<ul>
								<li>Investigated relationships between user experience and chatbot conversation topics</li>
								<li>Improved topical modules and conversation initiators based on user feedback analysis</li>
							</ul>

						</div>
					</div>
					<div class="no-top-margin publication">
						<p class="no-top-margin"><b>Audrey: A Personalized Open-Domain Conversational Bot</b></p>
						<p>Chung Hoon Hong, Yuan Liang, Sagnik Sinha Roy, Arushi Jain, Vihang Agarwal, Ryan Draves, Zhizhuo Zhou, William Chen, Yujian Liu, Martha Miracky, <b>Lily Ge</b>, Nikola Banovic, David Jurgens</p>
						<p>3rd Proceedings of Alexa Prize (Alexa Prize 2019)</p>
						<p><a target="_blank" rel="noopener noreferrer" href="https://m.media-amazon.com/images/G/01/mobile-apps/dex/alexa/alexaprize/assets/challenge3/proceedings/U-Michigan-Audrey.pdf">[ PDF ]</a></p>
					</div>
					{/*<div class="projectDetails">
						<div>
							<img class="block" src={MDP_Project_Intro_Slide_Screenshot} alt="MDP Project Intro Slide Screenshot"/>
						</div>
						<div class="details">
							<p><b>Multidisciplinary Design Program: Honda Project</b></p>
							<p>Student Software Engineer for Honda R&D Americas, Inc.</p>
							<p>Work with the Honda team to develop Machine Learning algorithms to detect wrong-way driving scenarios and integrate algorithm with embedded systems to create Vehicle-to-Vehicle (V2V) communication system to warn drivers.</p>
						</div>
					</div>*/}
				</div>
			</div>
		</Fragment>
	)
}

export { Research }