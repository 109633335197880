import React, { Fragment } from 'react';

function Footer() {
	return (
		<Fragment>
		<footer>
			<p>Updated: May, 2024</p>
		</footer>
		</Fragment>
	)
}

export { Footer }